import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  // other configuration options
});

// Add a request interceptor to dynamically set the Authorization header
api.interceptors.request.use(
  (config) => {
    const token = secureLocalStorage.getItem("user_session");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 498 errors
api.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    const errorDesc = error?.response?.data?.message;
    const errorCode = error?.response?.status;
    if (errorDesc && errorCode !== 498) {
      alert(errorDesc);
    }
    const currentPath = window.location.pathname;
    const loginPagePath = "/login";
    const profilePagePath = "/perfil";
    const defaultPagePath = "/";

    // Redirect only if not on the login page or the default page
    if (
      errorCode === 498 &&
      currentPath !== loginPagePath &&
      currentPath !== defaultPagePath &&
      currentPath !== profilePagePath
    ) {
      // Perform the logout action here
      secureLocalStorage.removeItem("user_session");
      window.location.href = loginPagePath;
    }

    // Reject the promise to handle other errors
    return Promise.reject(error);
  }
);

export default api;
