import { fetchGetApi, fetchPostApiNoAuth } from "./fetch";

export const getPassengers = async (userId) => {
  const res = await fetchGetApi(`/passengers?userId=${userId}`);
  return res;
};

export const getPassengerById = async (passengerId) => {
  const res = await fetchGetApi(`/passengers/${passengerId}`);
  return res;
};

export const addPassenger = async (passenger, groupId) => {
  const bodyData = {
    name: passenger.name,
    lastName: passenger.lastName,
    phone: passenger.phone,
    documentId: passenger.documentId,
    birthDate: passenger.birthDate,
    email: passenger.email,
    userId: passenger.userId,
    highschool: passenger.highschool,
    ammount: passenger.ammount,
    imageUrl: passenger.imageUrl,
    fileType: passenger.fileType,
  };
  const res = await fetchPostApiNoAuth(
    `/groups/${groupId}/passengers`,
    bodyData
  );
  return res;
};

export const getHighSchools = async () => {
  const res = await fetchGetApi(`/highschools/public`);
  return res;
};

export const getPassengerPaymentPlan = async (passengerCI) => {
  const res = await fetchGetApi(`/passengers/${passengerCI}/paymentsPlans`);
  return res;
};
