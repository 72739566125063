import Bugsnag from "@bugsnag/browser";
import { useNavigate } from "react-router-dom";
import { cleanSessionCookie } from "./session";
//returns array with two values: [true, "Error message"] where index 0 is
//session expired and index 1 is the error message returned by the server
export const processErrorResponse = (res) => {
  try {
    if (res.response.status === 400 || res.response.status === 404) {
      return [false, res.response?.data?.message];
    }
    if (res.response.status === 498) {
      cleanSessionCookie();
      // Bugsnag.notify(new Error(res.response.data));
      return [true, res.response?.data?.message];
    }
  } catch (e) {
    return [false, "Ocurrio un error"];
  }
};
